<template>
	<nav class="nav">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10081')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<Loading v-if="booking === undefined" />
	<div id="container" class="container container--fit" v-else>
		<div id="sub" class="sub booking">
			<div class="box">
				<!-- 예약 숙소 -->
				<section class="booking-top">
					<div class="box box--reverse">
						<h2 class="booking-top__title booking-top__title--small">{{t('10083')}}</h2>
						<div class="booking-top__date">
							<div class="flex">
								<span>{{booking.BOOKING_DATETIME}}</span>
								<span>
									<a @click="handleOpenMessage" class="btn-link">{{t('10509')}}</a>
								</span>
							</div>
						</div>
					</div>
				</section>
				<section class="content">
					<!-- 숙소 리스트 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<div class="booking-total">
								<h3 class="booking-total__title">
									{{t('10084')}}
								</h3>
								<div class="booking-total__desc">
									{{t('10085')}}
									<span>{{booking.BOOKING_NO}}</span>
								</div>
							</div>
							<!-- 예약 숙소 1 -->
							<div class="booking-item">
								<div class="booking-item__date">
									{{t('10086')}} <span>{{booking.BOOKING_NO}}</span>
								</div>
								<div class="booking-item__badge">숙소</div>
								<h3 class="booking-item__title">{{booking.HOTEL_NM}}</h3>
								<p class="booking-top__desc">
									<span class="booking-top__badge">{{booking.PROD_TYPE}}</span>
									{{booking.PROD_NM}} <!-- 방 타입 -->
								</p>
								<div class="booking-item__date">
									{{booking.checkin}} ~ {{booking.checkout}}<br>
									{{booking.VISIT_MEANS}}
								</div>
								<div class="booking-item__price">
									<strong>{{booking.SALES_AMT.toLocaleString("en-US")}}</strong>{{t('10088')}}
								</div>
								<div class="booking-item__select" v-if="booking.CANCEL_AVAIL_DATE !== null && availableCancel">
									<span class="description red">
										{{booking.CANCEL_AVAIL_DATE}}{{t('10089')}}
									</span>
									<span class="description red" v-html="mrm02022.map(x=> '<br />' + x.REFUND_DESC).join('')">
									</span>
								</div>
							</div>
						</div>
					</article>
					<!-- 예약자/방문자 정보 -->
					<article class="booking-box">
						<div class="box box--reverse">
							<!-- 예약자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> {{t('10090')}} </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											{{t('10091')}}
										</h4>
										<div class="info__text">
											{{booking.ISSUE_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											{{t('10092')}}
										</h4>
										<div class="info__text">
											{{booking.ISSUE_HP}}
										</div>
									</li>
								</ul>
							</div>
							<!-- 방문자 정보 -->
							<div class="booking-box__item">
								<h3 class="booking-box__title"> {{t('10453')}}  </h3>
								<ul class="info info--large">
									<li>
										<h4 class="info__title">
											{{t('10091')}}
										</h4>
										<div class="info__text">
											{{booking.GUEST_NM}}
										</div>
									</li>
									<li>
										<h4 class="info__title">
											{{t('10092')}}
										</h4>
										<div class="info__text">
											{{booking.GUEST_HP}}
										</div>
									</li>
								</ul>
							</div>
						</div>
					</article>
					<!-- 금액 및 결제 정보 -->
					<div class="booking-box booking-box--last">
						<div class="box box--reverse">
							<h3 class="booking-box__title">{{t('10095')}}</h3>
							<ul class="info">
								<li>
									<h4 class="info__title">
										{{t('10096')}}
									</h4>
									<div class="info__text">
										{{booking.PAY_TYPE}}
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10097')}}
									</h4>
									<div class="info__text">
										{{booking.SALES_AMT.toLocaleString("en-US")}}원
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10098')}}
									</h4>
									<div class="info__text">
										{{booking.DC_AMT_CP ? "-" : ""}}{{booking.DC_AMT_CP.toLocaleString("en-US")}}원
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10099')}}
									</h4>
									<div class="info__text">
										{{booking.DC_AMT_PT ? "-" : ""}}{{booking.DC_AMT_PT.toLocaleString("en-US")}}P
									</div>
								</li>
								<li>
									<h4 class="info__title">
										{{t('10100')}}
									</h4>
									<div class="info__text">
										{{booking.DC_AMT_OT ? "-" : ""}}{{booking.DC_AMT_OT.toLocaleString("en-US")}}원
									</div>
								</li>
								<li class="point">
									<h4 class="info__title">{{t('10101')}} </h4>
									<div class="info__wrap">
										<div class="info__text"><strong class="red">{{(booking.PAID_AMT-(booking.DC_AMT_CP+booking.DC_AMT_PT+booking.DC_AMT_OT)) > 0 ? (booking.PAID_AMT-(booking.DC_AMT_CP+booking.DC_AMT_PT+booking.DC_AMT_OT)).toLocaleString("en-US") : 0}}</strong>원</div>
									</div>
								</li>
							</ul>
							<div class="booking-box__button">
								<div class="btns">
									<router-link :to="'/mypage/booking/cancel/' + booking.BOOKING_NO + '?seq=' + booking.SEQ" custom v-slot="{ navigate }" v-if="availableCancel">
										<button type="button" class="btn btn-primary purple" @click="navigate">
											취소요청
										</button>
									</router-link>
									<router-link to="/" custom v-slot="{navigate}">
										<button type="button" class="btn btn-confirm" @click="navigate">
											홈으로
										</button>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
	<HistoryDelete v-if="openMessage" :handleClose="handleCloseMessage" :handleSubmit="handleSubmit"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router';
import HistoryDelete from '@/components/layers/Message_historydelete';
import dayjs from "dayjs";
import api from "@/urls/mmy02";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	components: {
		HistoryDelete
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const { t }= useI18n() //번역필수 모듈
		const router = useRouter();
		const openMessage = ref(false);
		const userData = computed(() => store.state.userData);
		const mrm02022 = computed(() =>{
			const finded = store.state.mmy02.mmy0202[route.params.id]?.[0];
			if(finded) {
				return store.state.mrm02.mrm02022[finded.HOTEL_NO]?.[finded.PROD_NO] ?? [];
			} else {
				return [];
			}
		});

		const booking = computed(() => {
			//console.log(route.query.seq)
			const finded = store.state.mmy02.mmy0202[route.params.id]?.find(item => item.SEQ === Number(route.query.seq));
			if(finded) {
				return {
					...finded,
					BOOKING_DATETIME: dayjs(finded.BOOKING_DATETIME).format("YY.MM.DD(dd) HH:mm"),
					checkin: dayjs(finded.SDATE).format("YYYY.MM.DD(dd)") + " " + finded.CHECKIN_TIME,
					checkout: finded.SDATE === finded.EDATE ? finded.CHECKOUT_TIME : dayjs(finded.EDATE).format("YYYY.MM.DD(dd)") + " " + finded.CHECKOUT_TIME,
				}
			}else {
				return undefined;
			}
		});


		onMounted(() => {
			store.dispatch("mmy02/fetchMmy0202", { 
				proc_cd: "01", 
				mem_id: userData.value.mem_token, 
				booking_no: route.params.id 
			}).then(x=>{
				if(x.data.Res_data.length > 0) {
					const hotelNo = x.data.Res_data[0].HOTEL_NO;
					const prodNo = x.data.Res_data[0].PROD_NO;
					store.dispatch("mrm02/fetchMrm02022", { hotelNo, prodNo });
				}
			});
		})

		return {
			mrm02022,
			booking,
			openMessage,
			handleOpenMessage: () => {
				openMessage.value = true;
			},
			handleCloseMessage: () => {
				openMessage.value = false;
			},
			handleSubmit: async () => {
				await api.getMmy0203({
					proc_cd: "01",
					mem_id: userData.value.mem_token,  
					booking_no: route.params.id
				})
				openMessage.value = false;
				router.back();
			},
			goBack: () => {
				router.back();
			},
			t,  //번역필수 모듈
		  i18n,
			availableCancel: computed(() => {
				const finded = store.state.mmy02.mmy0202[route.params.id]?.[0];
				if(finded) {
					return dayjs().isBefore(dayjs(finded.EDATE + " " + finded.CHECKOUT_TIME));
				} else {
					return true;
				}
			})
		}
	},
}
</script>
